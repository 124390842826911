  <template>
    <div>
      <b-input-group>
        <b-input-group-prepend>
          <b-button
              variant="outline-secondary"
              @click.stop="inputClick"
              class="corners-straight-right custom-button"
              :class="{ 'date-button-active': isActive && manualDateState!==false, 'prepend-invalid-border': manualDateState===false, 'date-button': manualDateState!==false }"
          >
              <rt-icon icon="calendar" variant="light" :size="14"/>
          </b-button>
        </b-input-group-prepend>
        <b-form-input
            v-if="showInput"
            class="rounded ren-input-date-picker corners-straight-left"
            v-model="manualDate"
            :state="manualDateState"
            @change="onContext"
            :placeholder="dateFormat"
            @keydown="onlyNumber($event)"
            autocomplete="off"
            @focus="isActive=true"
            @blur="isActive=false"
        />
              <VueCtkDateTimePicker
                  v-show="!showInput"
                  :id="'Datepick' + refIdRandom"
                  class="ren-date-picker-bar corners-straight-left"
                  :class="{'ren-date-picker-bar-border': isActive}"
                  :min-date="min"
                  :max-date="max"
                  :range="range"
                  :disabled="disabled"
                  :disabledWeekly="disabledWeekly"
                  v-model="tempValue"
                  @input="onContext"
                  @is-hidden="closed"
                  :only-date="onlyDate"
                  :only-time="false"
                  :format="`YYYY-MM-DD`"
                  :formatted="dateFormat"
                  :color="`#72a333`"
                  :label="i18nT(`Select date`)"
                  :button-now-translation="i18nT(`Today`)"
                  :first-day-of-week="1"
                  :no-button="true"
                  :auto-close="true"
                  :error="state || null"
                  :locale="locale"
                  :ref="`datepickerRef`+refIdRandom"
                  :customShortcuts="customShortcuts"
                  noLabel
                  :dark="isDarkModeOn"
                  @is-shown="isActive = true"
              />
      </b-input-group>
    </div>
  </template>

  <script>
  import {
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    //BFormDatepicker
  } from 'bootstrap-vue'
  import useAuth from '@/auth/useAuth'
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import moment from 'moment';
  import store from "@/store";

  //import Debugger from '@/views/Debugger.vue'

  export default {
    components: {
      BInputGroup,
      BButton,
      BFormInput,
      BInputGroupPrepend,
      //BFormDatepicker,
      //Debugger,
      VueCtkDateTimePicker
    },
    data() {
      return {
        isActive: false,
        selected: '',
        tempValue: '',
        manualDate: null,
        secondaryCheck: false,
        manualDateState: null,
        isInitContext: true,
        selectedDate: new Date(),
        refIdRandom: Math.floor(Math.random() * 20),
        customShortcuts: [
          { key: 'thisWeek', label: this.i18nT(`Cette semaine`), value: 'isoWeek' },
          { key: 'lastWeek', label: this.i18nT(`La semaine dernière`), value: '-isoWeek' },
          { key: 'last7Days', label: this.i18nT(`Dernier 7 jours`), value: 7 },
          { key: 'last30Days', label: this.i18nT(`Dernier 30 jours`), value: 30 },
          { key: 'thisMonth', label: this.i18nT(`Ce mois`), value: 'month' },
          { key: 'lastMonth', label: this.i18nT(`Mois dernier`), value: '-month' },
          { key: 'thisYear', label: this.i18nT(`Année en cours`), value: 'year' },
          { key: 'lastYear', label: this.i18nT(`Année dernière`), value: '-year' }
        ],
      }
    },
    props: {
      state: {
        type: Boolean,
        required: false
      },
      manualInput:{
        type: Boolean,
        default: false
      },
      value: {
        type: [String, Object]
      },
      disabled: {
        type: Boolean,
        default: false
      },
      min:null,
      max:null,
      onlyDate: {
        type: Boolean,
        default: true
      },
      range: {
        type: Boolean,
        default: false
      },
      disabledWeekly: {
        type: Array,
        default: () => []
      },

    },
    created() {
      this.secondaryCheck = this.manualInput
    },
    watch: {
        value: function(val) {
          this.tempValue = val
          const validDate = moment(val, 'YYYY-MM-DD').format(this.dateFormat) !== 'Invalid date'
          if(this.manualInput && val && val.length === 10 && validDate) {
            this.manualDate = moment(val, 'YYYY-MM-DD').format(this.dateFormat)
          } else if(val === null) {
            this.manualDate = ''
          }
      },
      state(val) {
          if(val == false) {
            this.manualDateState = false
          } else {
            this.manualDateState = null
          }
      },
      manualDate(newVal, oldVal) {
          if (newVal === '') {
            this.tempValue = null;
            return;
          }

          const isValid = moment(newVal, this.dateFormat).format('YYYY-MM-DD') !== 'Invalid date';

          if (isValid) {
            this.manualDateState = null;
            if (newVal.length < 10) {
              this.tempValue = newVal;
            } else if (newVal.length === 10) {
              this.tempValue = moment(newVal, this.dateFormat).format('YYYY-MM-DD');
            }
          } else {
            if (newVal.at(-1) !== '0' && newVal !== '') {
              this.manualDateState = false;
            }
          }

          const checkForForwardSlash = newVal.match(/\//g) ? newVal.match(/\//g).length < 2 : true;


          const isLengthIncrease = oldVal ? newVal.length > oldVal.length : false ;
          const isSlashNotAtEnd = newVal.slice(-1) !== '/';

          if ((this.dateFormat === 'DD/MM/YYYY' || this.dateFormat === 'MM/DD/YYYY') && checkForForwardSlash) {
            if ((newVal.length === 2 || newVal.length === 5) && isLengthIncrease && isSlashNotAtEnd) {
              this.manualDate = `${newVal}/`;
            } else if ((newVal.length === 3 || newVal.length === 6) && isLengthIncrease && isSlashNotAtEnd) {
              this.manualDate = newVal.slice(0, -1) + '/' + newVal.slice(-1);
            }
          } else if (checkForForwardSlash) {
            if ((newVal.length === 4 || newVal.length === 7) && isLengthIncrease && isSlashNotAtEnd) {
              this.manualDate = `${newVal}/`;
            } else if ((newVal.length === 5 || newVal.length === 8) && isLengthIncrease && isSlashNotAtEnd) {
              this.manualDate = newVal.slice(0, -1) + '/' + newVal.slice(-1);
            }
          }
        },
    },
    mounted() {
        if (this.value) {
          this.tempValue = this.value
          if(this.manualInput) {
            this.manualDate = moment(this.value, 'YYYY-MM-DD').format(this.dateFormat)
          }
        }
    },
    methods: {
        onlyNumber($event) {
          const input = $event.target;
          const textSelected = input.selectionStart !== input.selectionEnd;

          if (!/^\d$/.test($event.key) && $event.key !== 'Backspace') {
            $event.preventDefault();
          } else if (this.manualDate && this.manualDate.length >= 10 && $event.key !== 'Backspace' && !textSelected ) {
            $event.preventDefault();
          }
      },
      inputClick() {

          if(this.secondaryCheck) {
            this.secondaryCheck = false
          }

          const inputElement = this.$refs[`datepickerRef`+this.refIdRandom].$el.querySelector('input');
          if(!this.isActive){
            this.isActive = true
          }
          if (inputElement) {
            inputElement.click();
          }
        },
        closed() {
          this.secondaryCheck = true
          this.isActive = false
        },
        onContext() {
          if (this.tempValue) {
            this.$emit('input', this.tempValue)
          } else {
            this.$emit('input', null)
          }
        }
    },
    computed: {
        locale() {
          return useAuth.getLocale() !== 'undefined'
              ? useAuth.getLocale()
              : 'fr'
        },
        dateFormat() {
          // return useAuth.getDateFormat() !== 'undefined'
          //     ? useAuth.getDateFormat()
          //     : 'DD/MM/YYYY'
            return 'DD/MM/YYYY' //TODO make sure it works with useAuth function once endpoint is up
        },
        showInput() {
          if (this.manualInput && this.secondaryCheck) {
            return true;
          } else {
            return false;
          }
        },
        isDarkModeOn(){
            return store.state.appConfig.layout.skin === 'dark'
        }
    }
  }
  </script>

  <style lang="scss">

  div.date-time-picker {
    width: calc(100% - 56px);
  }

  .date-time-picker input.field-input {
    padding-right: 5px;
    height: 37.9844px;
    min-height: 37.9844px;

  }

  .ctk-date-time-picker-input {
    background-color: #283046 !important;
    color: #f8f9fa;
    border-color: #3b4253 !important;
  }

  .ctk-date-time-picker__header,
  .datetimepicker .custom-button,
  .datetimepicker span .custom-button-content,
  .datepicker-buttons-container .datepicker-button.now .datepicker-button-content
  {
    /* Change the header text color */
    color: #72a333 !important;
    fill: #524d4d !important;

  }
  .ren-date-picker-bar {
    z-index: 11;
  }
  .ren-input-date-picker {
      z-index: 10;
      //z-index: 900;
  }

  .date-button{
      border: 1px solid #d8d6de !important;
  }

  .date-button-active {
      border: 1px solid #72a333 !important;
  }

  //Dark mode

  .dark-layout .ren-date-picker-bar input,
  .dark-layout .ren-date-picker-bar,
  .dark-layout .datepicker-container,
  .dark-layout .year-month-selector,
  .dark-layout .custom-button,
  .dark-layout .field-input {
    background-color: #283046 !important;
    color: #a7a7a7 !important;
    border: 1px solid #3b4253 !important;

  }

  //.dark-layout .datepicker-day-text,
  //.dark-layout .field-label {
  //  color: #a7a7a7 !important;
  //}
  //
  //.dark-layout .custom-button {
  //  border-color: #72a333 !important;
  //    border: none;
  //}
  //
  //
  //.dark-layout .fs-16,
  //.dark-layout .datepicker-today {
  //  color: #72a333 !important;
  //}

  //.dark-layout .datepicker-today,{
  //  background-color: #72a333 !important;
  //}



  //.manual-date-button {
  //    border: 1px solid #d8d6de !important;
  //    padding: 0.438rem 1rem !important;
  //}
  //.dark-layout .manual-date-button {
  //    border: 1px solid #3b4253 !important;
  //}
  //
  //.dark-layout .date-button {
  //    border: 1px solid #3b4253 !important;
  //}


  .dark-layout .date-button {
      border: 1px solid #3b4253 !important;
  }

  //.dark-layout .ren-date-picker-bar-border {
  //      border: 1px solid #3b4253 !important;
  //}

  body.dark-layout .date-button-active {
      border: 1px solid #72a333 !important;
  }

  body.dark-layout .ren-date-picker-bar-border{
      border: 1px solid #72a333 !important;
  }





  </style>
